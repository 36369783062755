<template>
  <div class="networks">
    <div class="networks__block">
      <div class="networks__box">
        <h3 class="networks__title">Мы в соц. сетях</h3>
        <div class="networks__socials">
          <a href="https://t.me/berezka64_camp" class="networks__social">
            <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M38.5003 6.41602C29.9913 6.41602 21.8308 9.79621 15.814 15.813C9.79719 21.8298 6.41699 29.9903 6.41699 38.4994C6.41699 47.0084 9.79719 55.1689 15.814 61.1857C21.8308 67.2025 29.9913 70.5827 38.5003 70.5827C47.0094 70.5827 55.1699 67.2025 61.1867 61.1857C67.2035 55.1689 70.5837 47.0084 70.5837 38.4994C70.5837 29.9903 67.2035 21.8298 61.1867 15.813C55.1699 9.79621 47.0094 6.41602 38.5003 6.41602Z" fill="#29B6F6"/>
              <path d="M54.4618 24.0632L48.4526 54.7444C48.4526 54.7444 48.1943 56.1465 46.4554 56.1465C45.5314 56.1465 45.055 55.707 45.055 55.707L32.0387 44.9061L25.6702 41.6962L17.497 39.5225C17.497 39.5225 16.042 39.1022 16.042 37.8991C16.042 36.8965 17.5387 36.4184 17.5387 36.4184L51.7331 22.8344C51.7315 22.8328 52.7774 22.4574 53.5394 22.459C54.0078 22.459 54.542 22.6595 54.542 23.2611C54.542 23.6621 54.4618 24.0632 54.4618 24.0632Z" fill="white"/>
              <path d="M36.8957 48.9352L31.3998 54.3477C31.3998 54.3477 31.1608 54.5322 30.8415 54.5402C30.7309 54.5434 30.6122 54.5257 30.4902 54.4712L32.0367 44.9023L36.8957 48.9352Z" fill="#B0BEC5"/>
              <path d="M47.9601 29.1892C47.689 28.8363 47.1885 28.7722 46.8356 29.0401L25.667 41.7082C25.667 41.7082 29.0454 51.1599 29.5603 52.7962C30.0768 54.434 30.4907 54.4725 30.4907 54.4725L32.0371 44.9037L47.8093 30.3122C48.1622 30.0443 48.228 29.5422 47.9601 29.1892Z" fill="#CFD8DC"/>
            </svg>
          </a>
          <a target="_blank" href="https://vk.com/berezka64_camp" class="networks__social">
            <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M72.6612 64.01C72.6612 68.7884 68.7894 72.6602 64.011 72.6602H19.0301C14.2534 72.6602 10.3799 68.7884 10.3799 64.01V19.0291C10.3799 14.2507 14.2534 10.3789 19.0301 10.3789H64.011C68.7894 10.3789 72.6612 14.2507 72.6612 19.0291V64.01Z" fill="#1976D2"/>
            <path d="M62.1726 31.2111C62.2522 30.9499 62.2903 30.7077 62.2799 30.4914C62.2539 29.8652 61.8231 29.4102 60.8093 29.4102H56.2801C55.1366 29.4102 54.6089 30.1022 54.3009 30.7959C54.3009 30.7959 51.4775 36.6071 48.2233 40.4391C47.168 41.5481 46.6317 41.5204 46.0608 41.5204C45.7546 41.5204 44.9812 41.1502 44.9812 40.1346V31.1644C44.9812 29.9638 44.6819 29.4102 43.7149 29.4102H35.6719C34.9678 29.4102 34.601 29.9638 34.601 30.5191C34.601 31.673 36.1546 31.9498 36.3311 35.1833V41.4512C36.3311 42.9736 36.0664 43.2504 35.4955 43.2504C33.9557 43.2504 30.9247 38.0603 28.8954 31.2578C28.4559 29.9188 28.0165 29.4102 26.873 29.4102H22.3005C20.9805 29.4102 20.7607 30.0572 20.7607 30.7492C20.7607 31.9966 21.7988 38.7402 27.4646 46.4943C31.5735 52.1169 36.9867 55.3607 41.7789 55.3607C44.6819 55.3607 44.9795 54.622 44.9795 53.468V48.3264C44.9812 46.9406 45.2978 46.7105 46.2217 46.7105C46.8808 46.7105 48.2251 47.143 50.8201 50.1706C53.8131 53.6618 54.3563 55.3607 56.0725 55.3607H60.6017C61.6536 55.3607 62.2574 54.9195 62.2816 54.0632C62.2868 53.8452 62.2557 53.6012 62.1847 53.3296C61.8491 52.3331 60.3094 49.8972 58.389 47.5755C57.3251 46.2901 56.2749 45.0168 55.7922 44.3248C55.4687 43.8733 55.3459 43.5549 55.3615 43.2504C55.377 42.9304 55.5431 42.6259 55.7922 42.2003C55.7473 42.2003 61.6017 33.9809 62.1726 31.2111Z" fill="white"/>
            </svg>
          </a>
          <a v-if="false" target="_blank" href="" class="networks__social">
            <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73.6192 64.01C73.6192 68.7884 69.7474 72.6602 64.969 72.6602H19.9881C15.2114 72.6602 11.3379 68.7884 11.3379 64.01V19.0291C11.3379 14.2507 15.2114 10.3789 19.9881 10.3789H64.969C69.7474 10.3789 73.6192 14.2507 73.6192 19.0291V64.01Z" fill="#3F51B5"/>
            <path d="M60.4151 43.2487H54.5883V65.7392H45.9382V43.2487H40.748V36.3286H45.9382V32.1592C45.9416 26.0902 48.4623 22.4883 55.6125 22.4883H61.5085V29.4084H57.5519C54.7683 29.4084 54.5883 30.4464 54.5883 32.3893V36.3286H61.5085L60.4151 43.2487Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
      <img src="@/assets/images/photo/networks-1.png" alt="" class="networks__image">
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .networks {
    background-color: #b49cdd;
    background-image: url('~@/assets/images/photo/networks-bg.png');
    

    &__title {
      font-family: RF Dewi Expanded;
      font-size: 64px;
      font-weight: 900;
      line-height: 77px;
      color: white;
      margin: 0 0 30px;
      text-transform: uppercase;
      @media screen and (max-width: 1300px) {
        font-size: 46px;
        line-height: 55px;
      }
      @media screen and (max-width: 680px) {
        font-size: 24px;
        line-height: 29px;
      }
    }
    &__block {
      padding: 40px 0 60px;
      display: flex;
      max-width: 1080px;
      margin: 0 auto;

      @media screen and (max-width: 1300px) {
        max-width: 620px;
      }
      @media screen and (max-width: 680px) {
        max-width: 300px;
      }
    }
    &__box {
    }
    &__socials {
      display: flex;
      align-items: center;
    }
    &__social {
      @media screen and (max-width: 680px) {
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
    &__image {
      margin: 0 0 0 40px;
      object-fit: contain;
      @media screen and (max-width: 680px) {
        width: 122px;
        margin: 0;
      }
    }
  }
</style>